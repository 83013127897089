import gql from 'graphql-tag';

export const BOOKS_QUERY = gql`
  query {
    books {
      id
      title
      author
      isFinished
      isOwned
      isReading
      isDigital
    }
  }
`;

export const BOOK_SEARCH_QUERY = gql`
  query bookLookup($author: String, $title: String, $search: String) {
    bookLookup(author: $author, title: $title, search: $search) {
      volumeId
      title
      author
      isbn
      publishedDate
      images {
        thumbnail
        smallThumbnail
      }
    }
  }
`;
