import { useNavigate } from 'react-router';

export function useActiveFilter() {
  const queryParams = new URLSearchParams(location.search);
  const activeFilter = queryParams
    ? queryParams.get('activeFilter') || 'all'
    : 'all';
  const navigate = useNavigate();
  function handleFilterSelected(filterId: string) {
    queryParams.set('activeFilter', filterId);

    navigate({ search: `?${queryParams.toString()}` }, { replace: true });
  }

  return { activeFilter, handleFilterSelected };
}
