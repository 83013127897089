import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react';
import { Route, Routes, useLoaderData, useNavigate } from 'react-router-dom';

import { useStateReducer } from '~/components/shared/hooks';
import AddMediaButton from '~/components/shared/AddMediaButton';
import AddGame from './components/AddGame';
import EditGame from './components/EditGame';
import { useGameApi } from './VideoGameData';

import type { FilterState, GameConnection, Platforms } from './types';
import { SearchContext } from '~/SearchContext';
import { gql, useMutation, useQuery } from '@apollo/client';
import VideoGameFilter from './VideoGameFilters';
import GameList from './GameList';
import { GAME_FILTER_QUERY } from '~/queries';
import GameFilters from './components/GameFilters';
import { usePath } from '~/hooks';
import { useActiveFilter } from './hooks';

const ADD_UPDATE_GAME_FILTER_PREF_MUTATION = gql`
  mutation updateUserPreference($id: ID, $name: String!, $value: String!) {
    updateUserPreference(id: $id, name: $name, value: $value) {
      id
      name
      value
    }
  }
`;

function VideoGameRoutes({ platforms }: { platforms: Platforms }) {
  const navigate = useNavigate();
  const { addGame, updateGame, deleteGame } = useGameApi();

  const gamesPath = usePath('.');
  function navigateToGames() {
    navigate(gamesPath);
  }

  return (
    <Fragment>
      <AddMediaButton path="add" />
      <Routes>
        <Route
          path="add"
          element={
            <AddGame
              platforms={platforms}
              onClose={navigateToGames}
              addGame={(data) => addGame(data).then(navigateToGames)}
            />
          }
        />
        <Route
          path=":id"
          element={
            <EditGame
              platforms={platforms}
              onClose={navigateToGames}
              onDelete={(id) => deleteGame(id).then(navigateToGames)}
              updateGame={(data) => updateGame(data).then(navigateToGames)}
            />
          }
        />
      </Routes>
    </Fragment>
  );
}

function VideoGames() {
  const { activeFilter, handleFilterSelected } = useActiveFilter();

  const { platformData, gameFilters } = useLoaderData() as {
    platformData: { gamePlatforms: Platforms };
    gameFilters: { value: FilterState; id: string };
  };
  const { searchValue } = useContext(SearchContext);
  const platforms = platformData?.gamePlatforms ?? [];

  const [state, dispatch] = useStateReducer<FilterState>(gameFilters.value);
  const platformIds = Object.keys(state.platforms).filter(
    (k) => state.platforms[k],
  );

  function getQueryVariables(activeFilter: string) {
    switch (activeFilter) {
      case 'wishlist': {
        return {
          platformIds: [],
          isWishlist: true,
          sortDirection: 'DESC',
        };
      }
      case 'playing': {
        return {
          platformIds: [],
          isPlaying: true,
          sortDirection: 'DESC',
        };
      }
      default: {
        return {
          platformIds,
          showRecent: false,
          isFinished: state.isFinished,
          isPlaying: state.isPlaying,
          isWishlist: state.isOwned
            ? false
            : state.isOwned === undefined
            ? undefined
            : true,
          isDigital: state.isDigital,
          searchTerm: searchValue,
          sortDirection: state.sort,
        };
      }
    }
  }
  const queryVariables = getQueryVariables(activeFilter);
  const { data: gameData } = useQuery<{ games: GameConnection }>(
    GAME_FILTER_QUERY,
    {
      variables: queryVariables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  );
  const [updateUserPreferenceMutation] = useMutation(
    ADD_UPDATE_GAME_FILTER_PREF_MUTATION,
  );
  const games = useMemo(
    () => gameData?.games.edges?.map((edge) => edge.node) ?? [],
    [gameData],
  );

  const updatePrefRef = useRef(false);
  useEffect(() => {
    if (updatePrefRef.current) {
      const value = JSON.stringify(state);
      updateUserPreferenceMutation({
        variables: { id: gameFilters.id, name: 'game_filters', value },
      });
    }

    updatePrefRef.current = true;
  }, [state]);

  return (
    <div>
      <div className="sticky z-20 inset-x-0 top-0">
        <GameFilters
          activeFilter={activeFilter}
          onFilterSelected={handleFilterSelected}
        />
      </div>
      {activeFilter === 'all' && (
        <VideoGameFilter
          state={state}
          dispatch={dispatch}
          platforms={platforms}
          games={games}
        />
      )}
      <GameList
        state={state}
        dispatch={dispatch}
        platforms={platforms}
        games={games}
      />
      <VideoGameRoutes platforms={platforms} />
    </div>
  );
}

export default VideoGames;
