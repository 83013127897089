import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Autocomplete, TextInput, Toggle } from '~/components/shared/inputs';
import { BOOK_SEARCH_QUERY } from '../queries';
import { BookReference } from './types';

type Props = {
  author: string;
  title: string;
  isFinished?: boolean;
  isOwned?: boolean;
  isReading?: boolean;
  isDigital?: boolean;
  onChange: (value: string | boolean | null | undefined, key: string) => void;
};

function BookForm({
  author,
  title,
  isFinished = false,
  isOwned = false,
  isReading = false,
  isDigital = false,
  onChange,
}: Props) {
  const [searchBooks, { data }] = useLazyQuery(BOOK_SEARCH_QUERY);
  const hasSearchResult = data && !!author;
  const items: Array<BookReference> = hasSearchResult ? data.bookLookup : [];

  function handleChange(value: string | null | undefined) {
    onChange(value, 'author');
  }

  function handleSelectedItem(value: BookReference) {
    onChange(value.title, 'title');
    onChange(value.author, 'author');
  }

  useEffect(() => {
    if (author) {
      searchBooks({ variables: { author } });
    }
  }, [author]);

  return (
    <div>
      <Autocomplete
        value={author}
        onTextChange={handleChange}
        onItemSelected={handleSelectedItem}
        label="Enter Author"
        labelKey="author"
        items={items}
        itemRender={(item: BookReference) => (
          <div className="flex" key={item.volumeId}>
            <div className="w-10 h-10 flex border border-solid border-black">
              {item.images[0] ? (
                <img alt="Game Cover" src={item.images[0].thumbnail} />
              ) : (
                ''
              )}
            </div>
            <div className="ml-4 flex justify-between flex-wrap">
              <div className="w-full">{item.title}</div>
              <div className="w-full">{item.author}</div>
              <div className="w-full">{item.publishedDate}</div>
            </div>
          </div>
        )}
      />
      <TextInput
        placeholder="Title"
        label="Enter Title"
        value={title}
        type="text"
        onChange={(value) => onChange(value, 'title')}
      />

      <Toggle
        checked={isFinished}
        onChange={(checked) => onChange(checked, 'isFinished')}
        label="Have you read this book?"
      />

      <Toggle
        checked={isOwned}
        onChange={(checked) => onChange(checked, 'isOwned')}
        label="Do you own this book?"
      />

      <Toggle
        checked={isReading}
        onChange={(checked) => onChange(checked, 'isReading')}
        label="Are you currently reading this book?"
      />

      <Toggle
        checked={isDigital}
        onChange={(checked) => onChange(checked, 'isDigital')}
        label="Is this book digital?"
      />
    </div>
  );
}

export default BookForm;
