import React, { Fragment, useEffect, useState, useContext } from 'react';
import {
  Route,
  Routes,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import BookFilters from './components/BookFilters';
import BookItem from './components/BookItem';
import AddBook from './components/AddBook';
import EditBook from './components/EditBook';

import AddMediaButton from '~/components/shared/AddMediaButton';
import List from '~/components/shared/List';
import type { Book, BookFilter } from './components/types';
import { SearchContext } from '~/SearchContext';
import classNames from 'classnames';
import { BOOKS_QUERY } from './queries';
import { useIsLoading, usePath } from '~/hooks';

export const FILTER_BAR_HEIGHT = '40px';
export const MOBILE_HEADER_HEIGHT = '60px';
export const DESKTOP_HEADER_HEIGHT = '80px';
export const MOBILE_BOTTOM_NAV_HEIGHT = '70px';

function bookSortFunc(b1: Book, b2: Book) {
  const firstAuthor = b1.author.toLowerCase();
  const secondAuthor = b2.author.toLowerCase();

  if (firstAuthor < secondAuthor) {
    return -1;
  } else if (firstAuthor > secondAuthor) {
    return 1;
  } else {
    // author same, sort by title
    const firstTitle = b1.title.toLowerCase();
    const secondTitle = b2.title.toLowerCase();

    if (firstTitle < secondTitle) {
      return -1;
    } else if (firstTitle > secondTitle) {
      return 1;
    } else {
      return 0;
    }
  }
}

function bookFilterFunc(
  book: Book,
  activeFilter: BookFilter,
  searchTerm: string,
) {
  let result = true;

  if (searchTerm) {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const containsAuthor =
      book.author.toLowerCase().indexOf(searchTermLowerCase) !== -1;
    const containsTitle =
      book.title.toLowerCase().indexOf(searchTermLowerCase) !== -1;

    result = containsAuthor || containsTitle;
  }

  switch (activeFilter) {
    case 'reading':
      result = result && book.isReading;
      break;
    case 'wishlist':
      result = result && !book.isOwned;
      break;
    default:
      result = result && true;
  }

  return result;
}

type BooksByAuthorType = {
  [author: string]: {
    [data: string]: Array<Book> | { totalCompleted: number };
    meta: { totalCompleted: number };
  };
};
function getBooksByAuthor(books: Array<Book>): BooksByAuthorType | null {
  if (!books || books.length === 0) {
    return null;
  }
  const booksByAuthor = books.reduce((booksByAuthor, currentBook) => {
    const author = currentBook.author;

    if (!booksByAuthor[author]) {
      booksByAuthor[author] = {
        data: [],
        meta: {
          totalCompleted: 0,
        },
      };
    }

    const dataList = booksByAuthor[author].data;
    if (Array.isArray(dataList)) {
      dataList.push(currentBook);
    }

    if (currentBook.isFinished) {
      booksByAuthor[author].meta.totalCompleted++;
    }

    return booksByAuthor;
  }, {} as BooksByAuthorType);

  return booksByAuthor;
}

const ADD_BOOK_MUTATION = gql`
  mutation addBook(
    $author: String!
    $title: String!
    $isFinished: Boolean!
    $isOwned: Boolean!
    $isReading: Boolean!
    $isDigital: Boolean!
  ) {
    addBook(
      author: $author
      title: $title
      isFinished: $isFinished
      isOwned: $isOwned
      isReading: $isReading
      isDigital: $isDigital
    ) {
      id
      title
      author
      isFinished
      isOwned
      isReading
      isDigital
    }
  }
`;

const UPDATE_BOOK_MUTATION = gql`
  mutation updateBook(
    $id: ID!
    $author: String
    $title: String
    $isFinished: Boolean!
    $isOwned: Boolean!
    $isReading: Boolean!
    $isDigital: Boolean!
  ) {
    updateBook(
      id: $id
      author: $author
      title: $title
      isFinished: $isFinished
      isOwned: $isOwned
      isReading: $isReading
      isDigital: $isDigital
    ) {
      id
      title
      author
      isFinished
      isOwned
      isReading
      isDigital
    }
  }
`;

const DELETE_BOOK_MUTATION = gql`
  mutation deleteBook($id: ID!) {
    deleteBook(id: $id) {
      id
      title
      author
    }
  }
`;

function getNewState(
  queryParams: URLSearchParams,
  books: Array<Book> | undefined,
  searchValue: string,
): { booksByAuthor: BooksByAuthorType | null; hasBooks: boolean } {
  const activeFilter = (queryParams.get('activeFilter') as BookFilter) || 'all';
  const visibleBooks =
    books?.filter((b) => bookFilterFunc(b, activeFilter, searchValue)) ?? [];
  const sortedBooks = visibleBooks.sort(bookSortFunc);
  const booksByAuthor = getBooksByAuthor(sortedBooks);
  const hasBooks = !!sortedBooks.length;

  return { booksByAuthor, hasBooks };
}

type BooksQuery = { books: Array<Book> };
function Books() {
  const { searchValue } = useContext(SearchContext);
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useIsLoading();
  const { data } = useLoaderData() as ApolloQueryResult<BooksQuery>;
  const [updateBookMutation] = useMutation<{ updateBook: Book }, Book>(
    UPDATE_BOOK_MUTATION,
  );
  const [addBookMutation] = useMutation<{ addBook: Book }, Omit<Book, 'id'>>(
    ADD_BOOK_MUTATION,
  );
  const [deleteBookMutation] = useMutation<
    { deleteBook: Book },
    { id: string }
  >(DELETE_BOOK_MUTATION);
  const [booksByAuthor, setBooksByAuthor] = useState<null | BooksByAuthorType>(
    null,
  );
  const queryParams = new URLSearchParams(location.search);

  async function deleteBook(id: string) {
    const confirmation = window.confirm(
      'Are you sure you want to delete this book?',
    );

    if (confirmation) {
      await deleteBookMutation({
        variables: {
          id,
        },
        update: (proxy, { data }) => {
          const queryData = proxy.readQuery<BooksQuery>({ query: BOOKS_QUERY });

          if (queryData && data) {
            queryData.books = queryData.books.filter(
              (b) => b.id !== data.deleteBook.id,
            );
            proxy.writeQuery({ query: BOOKS_QUERY, data });
          }
        },
      });

      navigateToBooks();
    }
  }

  const booksPath = usePath('.');
  function navigateToBooks() {
    navigate(booksPath);
  }

  async function updateBook(data: Book) {
    const { id, author, title, isFinished, isOwned, isReading, isDigital } =
      data;

    await updateBookMutation({
      variables: {
        id,
        author,
        title,
        isFinished,
        isOwned,
        isReading,
        isDigital,
      },
    });

    navigateToBooks();
  }

  async function addBook(data: Omit<Book, 'id'>) {
    const { author, title, isFinished, isOwned, isReading, isDigital } = data;

    await addBookMutation({
      variables: {
        author,
        title,
        isFinished,
        isOwned,
        isReading,
        isDigital,
      },
      update: (cache, { data: { addBook } }: any) => {
        cache.modify({
          fields: {
            books(existingBooks = []) {
              return [...existingBooks, { node: addBook }];
            },
          },
        });
      },
    });

    navigateToBooks();
  }

  function handleFilterSelected(filterId: string) {
    queryParams.set('activeFilter', filterId);

    navigate({ search: `?${queryParams.toString()}` }, { replace: true });
  }

  const activeFilter = queryParams
    ? queryParams.get('activeFilter') || 'all'
    : 'all';
  const showExtraHeader = activeFilter === 'all';

  useEffect(() => {
    if (data) {
      const newState = getNewState(queryParams, data.books, searchValue);

      setBooksByAuthor(newState.booksByAuthor);
    }
  }, [data, searchValue, location.search]);

  if (loading) {
    return <Fragment>Loading...</Fragment>;
  }

  return (
    <div className="relative">
      <div className="sticky z-20 inset-x-0 top-0">
        <BookFilters
          activeFilter={activeFilter}
          onFilterSelected={handleFilterSelected}
        />
      </div>
      <List
        items={booksByAuthor as any}
        dataKey="data"
        metaKey="meta"
        itemRender={(book: Book) => <BookItem key={book.id} book={book} />}
        itemGroupRender={(
          author,
          _sectionIndex,
          totalBooks,
          meta,
          groupStyles,
        ) => (
          <div className={classNames('top-10', groupStyles)}>
            {author}
            {showExtraHeader && (
              <div className="ml-auto">
                Read: {meta.totalCompleted}/{totalBooks}
              </div>
            )}
          </div>
        )}
        noResults={() => 'You have no books here'}
      />
      <AddMediaButton path="add" />
      <Routes>
        <Route
          path="add"
          element={<AddBook onClose={navigateToBooks} addBook={addBook} />}
        />
        <Route
          path=":id"
          element={
            <EditBook
              onClose={navigateToBooks}
              onDelete={deleteBook}
              updateBook={updateBook}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default Books;
