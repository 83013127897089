import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faBook,
  faCheckCircle,
  faFolderBlank,
} from '@fortawesome/free-solid-svg-icons';

import RaisedShadow from '~/components/shared/RaisedShadow';
import type { Book } from './types';
import { usePath } from '~/hooks';

type Props = {
  book: Book;
};

function BookItem({ book }: Props) {
  const { id, author, title, isFinished = false, isOwned } = book;
  const path = usePath(id);

  return (
    <div className="flex-1 flex-shrink-0 max-w-full">
      <Link to={path} state={{ book }}>
        <RaisedShadow>
          <div>{author}</div>
          <div>{title}</div>
          {isFinished && (
            <div className="absolute top-2 right-2 text-primary">
              <FontAwesomeIcon size="lg" icon={faCheckCircle} />
            </div>
          )}
          {!isOwned && (
            <div className="absolute bottom-2 right-2 text-accent">
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon size="xl" icon={faBook} />
                <FontAwesomeIcon
                  size="sm"
                  icon={faBan}
                  transform="shrink-1 left-15"
                  className="text-primaryFocus"
                />
              </span>
            </div>
          )}
        </RaisedShadow>
      </Link>
    </div>
  );
}

export default BookItem;
