import React from 'react';
import classNames from 'classnames';
import { BookFilter } from './types';

type FilterItem = {
  id: BookFilter;
  label: string;
};
const items: FilterItem[] = [
  { id: 'all', label: 'All' },
  { id: 'reading', label: 'Reading' },
  { id: 'wishlist', label: 'Wishlist' },
];

type Props = {
  activeFilter: string;
  onFilterSelected: (filterId: string) => void;
};

function BookFilters({ activeFilter, onFilterSelected }: Props) {
  return (
    <div className="flex text-center bg-white text-primary font-semibold cursor-pointer drop-shadow-sm border-b border-solid border-primary">
      {items.map(({ id, label }) => (
        <div
          key={id}
          onClick={() => onFilterSelected(id)}
          className={classNames(
            'flex-1 flex-shrink-0 w-1/3 p-2 border-r border-solid border-primary last:border-none',
            {
              'bg-primaryFocus text-white': id === activeFilter,
            },
          )}
        >
          <div>{label}</div>
        </div>
      ))}
    </div>
  );
}

export default BookFilters;
