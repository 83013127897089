const API_PORT = ':4000';
const DEPLOYED_PATH = 'https://mediaman.gigalixirapp.com';
const AUTH_NAMESPACE = 'auth';
const GRAPHQL_NAMESPACE = 'graphql';
const UPLOAD_NAMESPACE = 'upload';
const DOWNLOAD_NAMESPACE = 'download';
const INTEGRATIONS_NAMESPACE = 'integrations';

function getOrigin(): string {
  if (window.location && window.location.origin) {
    return window.location.origin;
  }

  return '';
}

function isLocal(): boolean {
  const origin = getOrigin();

  return origin.indexOf('mediaman') === -1;
}

function getRootPath(): string {
  const local = isLocal();

  if (local) {
    const origin = getOrigin();
    return origin.replace(/:[\d]+/, API_PORT);
  }

  return DEPLOYED_PATH;
}

function getAuthUrl(): string {
  const rootPath = getRootPath();

  return `${rootPath}/${AUTH_NAMESPACE}`;
}

function getGraphqlUrl(): string {
  const rootPath = getRootPath();

  return `${rootPath}/${GRAPHQL_NAMESPACE}`;
}

function getUploadUrl(): string {
  const rootPath = getRootPath();

  return `${rootPath}/${UPLOAD_NAMESPACE}`;
}

function getDownloadUrl(): string {
  const rootPath = getRootPath();

  return `${rootPath}/${DOWNLOAD_NAMESPACE}`;
}

function getIntegrationUrl(): string {
  const rootPath = getRootPath();

  return `${rootPath}/${INTEGRATIONS_NAMESPACE}`;
}

export const AUTH_URL = getAuthUrl();
export const GRAPHQL_URL = getGraphqlUrl();
export const UPLOAD_URL = getUploadUrl();
export const DOWNLOAD_URL = getDownloadUrl();
export const INTEGRATIONS_URL = getIntegrationUrl();
